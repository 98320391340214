import React from 'react';
import './SectionWithBoxes.css';

// Importe as imagens PNG
import icon1 from '../assets/icons/icon1.png';
import icon2 from '../assets/icons/icon2.png';
import icon3 from '../assets/icons/icon3.png';
import icon4 from '../assets/icons/icon4.png';
import icon5 from '../assets/icons/icon5.png';

function SectionWithBoxes() {
    return (
        <div className="section-with-boxes">
            <p className="section-title">Benefícios de ser Webda</p>
            <p className="section-subtitle">Conexão de verdade | 100% Fibra óptica</p>
            <div className="box-container">
                <div className="box">
                    <div className="box-icon center-icon">
                        <img src={icon1} alt="Suporte 24h" className="icon-img" />
                    </div>
                    <p>Suporte 24h</p>
                </div>
                <div className="box">
                    <div className="box-icon center-icon">
                        <img src={icon2} alt="+ 50 cidades atendidas" className="icon-img" />
                    </div>
                    <p>+ 50 cidades
                        atendidas</p>
                </div>
                <div className="box">
                    <div className="box-icon center-icon">
                        <img src={icon3} alt="Wifi 5G incluso" className="icon-img" />
                    </div>
                    <p>Wifi 5G incluso</p>
                </div>
                <div className="box">
                    <div className="box-icon center-icon">
                        <img src={icon4} alt="Aplicativo para o cliente" className="icon-img" />
                    </div>
                    <p>Aplicativo para o cliente</p>
                </div>
                <div className="box">
                    <div className="box-icon center-icon">
                        <img src={icon5} alt="Garantia de satisfação" className="icon-img" />
                    </div>
                    <p>Garantia de satisfação</p>
                </div>
            </div>

            <h5>Listamos só 5 (cinco), mas aqui você tem tantos benefícios que não cabem todos nessa lista :)</h5>
        </div>
    );
}

export default SectionWithBoxes;